import React, { useState } from "react"
import validateEmail from "../../utils/validateEmail"

import "./Footer.scss"

function Footer() {
  const [emailIsValid, setEmailIsValid] = useState(false)
  const [email, setEmail] = useState("")

  function handleSubmitForm(event) {
    event.preventDefault()

    if (!emailIsValid) {
      event.stopPropagation()

      return
    }

    setTimeout(() => {
      setEmail("")
      setEmailIsValid(false)
    }, 2000)

    alert("Cadastrado")
    // "https://drive.google.com/drive/u/1/folders/1yBfygiTbyD9V_zAg3Kt3YZCTjlecmhrv"
    window.open(
      "https://drive.google.com/file/d/1VKwL9Jf3sUjC43SdG2GX2FeBqLmdOKu7/view"
    )
  }

  function handleEmailChange(event) {
    const { value } = event.target
    setEmail(value)
    setEmailIsValid(validateEmail(value))
  }

  return (
    <footer id="footer">
      <div id="container">
        <div id="group" style={{ marginBottom: 0 }}>
          <section className="newsletter" onSubmit={handleSubmitForm}>
            <h1>Acesse o Mini E-book</h1>
            <p>Gestão da Transformação Digital</p>
            <form id="newsletterOfebook">
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Seu email"
              />
              <button
                className={`btn ${!emailIsValid ? "disabled" : "abled"}`}
                disabled={!emailIsValid}
                type="submit"
                // onClick={handleSubmitButton}
              >
                acessar e-book
              </button>
            </form>
          </section>
          <div className="solutions-contact">
            <section className="solutions">
              <h1>Soluções</h1>
              <ul>
                <li>
                  <a href="/fabtech/#">FAB tech</a>
                </li>
                <li>
                  <a href="/fabcorporate/#">FAB corporate</a>
                </li>
                <li href="/contact/#">FAB academy</li>
              </ul>
            </section>
            <section className="contact">
              <h1>Contato</h1>
              <ul>
                <li>oi@fab.work</li>
              </ul>
            </section>
          </div>
        </div>
        <section className="social-networks">
          <h1>Redes Sociais</h1>
          <div>
            <a
              href="https://www.instagram.com/fab.work/"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                id="instagram"
                width="39"
                height="37"
                viewBox="0 0 39 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.8105 37H11.9582C5.36578 37 0 31.6388 0 25.0464V11.9536C0 5.36119 5.36578 0 11.9582 0H26.8105C33.4029 0 38.7641 5.36119 38.7641 11.9536V25.0418C38.7641 31.6388 33.4029 37 26.8105 37ZM11.9582 2.65533C6.83126 2.65533 2.65992 6.82667 2.65992 11.9536V25.0418C2.65992 30.1687 6.83126 34.3401 11.9582 34.3401H26.8105C31.9374 34.3401 36.1088 30.1687 36.1088 25.0418V11.9536C36.1088 6.82667 31.9374 2.65533 26.8105 2.65533H11.9582Z"
                  fill="#888888"
                />
                <path
                  d="M19.3822 29.1445C13.5157 29.1445 8.73792 24.3714 8.73792 18.5002C8.73792 12.6337 13.5111 7.85596 19.3822 7.85596C25.2487 7.85596 30.0265 12.6291 30.0265 18.5002C30.0265 24.3714 25.2533 29.1445 19.3822 29.1445ZM19.3822 10.5159C14.9811 10.5159 11.3978 14.0992 11.3978 18.5002C11.3978 22.9059 14.9811 26.4846 19.3822 26.4846C23.7832 26.4846 27.3665 22.9013 27.3665 18.5002C27.3665 14.0992 23.7878 10.5159 19.3822 10.5159Z"
                  fill="#888888"
                />
                <path
                  d="M32.0706 8.18627C32.0706 9.40368 31.0829 10.396 29.8609 10.396C28.6389 10.396 27.6512 9.40827 27.6512 8.18627C27.6512 6.96427 28.6389 5.97656 29.8609 5.97656C31.0829 5.97656 32.0706 6.96427 32.0706 8.18627Z"
                  fill="#888888"
                />
              </svg>
            </a>

            <a
              href="https://www.linkedin.com/company/fabwork"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                id="linkedin"
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.60207 11.25H0.591858V33.4694H7.60207V11.25Z"
                  fill="#888888"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.19389 4.09695C8.19389 6.36226 6.35715 8.1939 4.09694 8.1939C1.83163 8.1939 0 6.35716 0 4.09695C0 1.83675 1.83674 1.06036e-05 4.09694 1.06036e-05C6.35715 -0.00509144 8.19389 1.83165 8.19389 4.09695Z"
                  fill="#888888"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M26.5765 10.4287C21.8265 10.4287 19.8009 12.8879 19.2193 13.7961V11.2501H12.2091V33.4696H19.2193V20.3522C19.2193 20.3522 19.8265 16.4491 23.1224 16.4491C26.4183 16.4491 26.9948 20.0308 26.9948 21.041V33.5716H33.9999V20.3573C33.9999 16.1277 32.3979 10.4287 26.5765 10.4287Z"
                  fill="#888888"
                />
              </svg>
            </a>

            <a
              href="https://open.spotify.com/show/4LwRqtEtajrueY7BuPfN1N"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                id="spotify"
                xmlns="http://www.w3.org/2000/svg"
                height="37px"
                width="37px"
                version="1.1"
                viewBox="0 0 168 168"
              >
                <path
                  fill="#888888"
                  d="m83.996 0.277c-46.249 0-83.743 37.493-83.743 83.742 0 46.251 37.494 83.741 83.743 83.741 46.254 0 83.744-37.49 83.744-83.741 0-46.246-37.49-83.738-83.745-83.738l0.001-0.004zm38.404 120.78c-1.5 2.46-4.72 3.24-7.18 1.73-19.662-12.01-44.414-14.73-73.564-8.07-2.809 0.64-5.609-1.12-6.249-3.93-0.643-2.81 1.11-5.61 3.926-6.25 31.9-7.291 59.263-4.15 81.337 9.34 2.46 1.51 3.24 4.72 1.73 7.18zm10.25-22.805c-1.89 3.075-5.91 4.045-8.98 2.155-22.51-13.839-56.823-17.846-83.448-9.764-3.453 1.043-7.1-0.903-8.148-4.35-1.04-3.453 0.907-7.093 4.354-8.143 30.413-9.228 68.222-4.758 94.072 11.127 3.07 1.89 4.04 5.91 2.15 8.976v-0.001zm0.88-23.744c-26.99-16.031-71.52-17.505-97.289-9.684-4.138 1.255-8.514-1.081-9.768-5.219-1.254-4.14 1.08-8.513 5.221-9.771 29.581-8.98 78.756-7.245 109.83 11.202 3.73 2.209 4.95 7.016 2.74 10.733-2.2 3.722-7.02 4.949-10.73 2.739z"
                />
              </svg>
            </a>
          </div>
        </section>
      </div>
      <section className="fabwork-copyright" style={{ marginTop: 0 }}>
        <p>
          <strong>FABWORK</strong> :Transformação digital para sua realidade.
          Todos os direitos reservados.
        </p>
      </section>
      <svg
        style={{ width: 0, height: 0, position: "absolute" }}
        aria-hidden="true"
        focusable="false"
      >
        <linearGradient id="my-cool-gradient" x2="1" y2="1">
          <stop offset="0%" stopColor="#285aeb" />
          <stop offset="5%" stopColor="#285aeb" />
          <stop offset="45%" stopColor="#d6249f" />
          <stop offset="60%" stopColor="#fd5949" />
          <stop offset="90%" stopColor="#fdf497" />
        </linearGradient>
      </svg>
    </footer>
  )
}

export default Footer
