import React, { useState } from "react"
import { FiX } from "react-icons/fi"
import { FiMenu } from "react-icons/fi"

import logo from "../../images/FABWORK-log.svg"
import "./Header.scss"
import arrow_header from "../../images/shapes/arrow-header.svg"

const Header = () => {
  const [open, setOpen] = useState(false)

  function expandSubItemsMobile(solution = "") {
    if (solution === "tech") {
      const subItemsTech = document.getElementById("sub-items-tech")
      if (subItemsTech.classList.contains("expanded")) {
        subItemsTech.classList.remove("expanded")
      } else {
        subItemsTech.classList.add("expanded")
      }
    }

    if (solution === "corporate") {
      const subItemsTech = document.getElementById("sub-items-corporate")
      if (subItemsTech.classList.contains("expanded")) {
        subItemsTech.classList.remove("expanded")
      } else {
        subItemsTech.classList.add("expanded")
      }
    }
  }

  return (
    <div>
      <nav
        id="navbar"
        className="navbar navbar-expand-lg navbar-dark fixed-top"
        style={{ backgroundColor: "#000" }}
      >
        <a className="navbar-brand" href="/#">
          <img src={logo} alt="Fabwork" />
        </a>

        <button
          className="navbar-toggler collapsed"
          type="button"
          onClick={() => setOpen(!open)}
          data-toggle="collapse"
          data-target="#navbarNav"
          // aria-controls="navbarNav"
          aria-controls="responsive-navbar-nav"
          aria-expanded={open}
          aria-label="Toggle navigation"
        >
          <span id="navbar-icon">
            <FiX
              size={30}
              color={"#000"}
              id="x-icon"
              className={`${open ? "active" : "deactive"}`}
            />
            <FiMenu
              size={30}
              color={"#FFF"}
              id="menu-icon"
              className={`${!open ? "active" : "deactive"}`}
            />
          </span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul id="navbar-sm" className="navbar-nav" style={{ zIndex: 1001 }}>
            <li className="nav-item">
              <a className="nav-link" href="/fabwork/#">
                Sobre
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#section-two">
                Soluções
              </a>
              <ul>
                <li className="nav-link sub-item">
                  <div className="sub-items-mobile-container">
                    <div className="sub-items-header">
                      <a id="fabtech-sub-item" href="/fabtech/#">
                        FAB tech
                      </a>
                      <button
                        type="button"
                        id="triangle-down"
                        className="btn-triangle"
                        onClick={() => {
                          expandSubItemsMobile("tech")
                        }}
                      >
                        {" "}
                      </button>
                    </div>
                    <div id="sub-items-tech" className="sub-items-mobile">
                      <a
                        className="dropdown-item"
                        href="/fabtech/digital-products/#"
                      >
                        Produtos digitais
                      </a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" href="/fabtech/data-science">
                        Ciência de dados
                      </a>
                    </div>
                  </div>
                </li>
                <li className="nav-link sub-item" id="fabcorporate-sub-item">
                  <div className="sub-items-mobile-container">
                    <div className="sub-items-header">
                      <a id="fabcorporate-sub-item" href="/fabcorporate/#">
                        FAB corporate
                      </a>
                      <button
                        type="button"
                        id="triangle-down"
                        className="btn-triangle"
                        onClick={() => {
                          expandSubItemsMobile("corporate")
                        }}
                      >
                        {" "}
                      </button>
                    </div>
                    <div id="sub-items-corporate" className="sub-items-mobile">
                      <a
                        className="dropdown-item"
                        href="/fabcorporate/data-driven"
                      >
                        Data Driven
                      </a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" href="/fabcorporate/next">
                        Next
                      </a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" href="/fabcorporate/agile">
                        Agile
                      </a>
                    </div>
                  </div>
                </li>
                <li className="nav-link sub-item" id="fabcorporate-sub-item">
                  <a id="fabacademy-sub-item" href="/fabacademy/#">
                    FAB academy
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a style={{ textDecoration: "none" }} href="/#companies">
                Clientes
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#sectionFour">
                Conteúdos
              </a>
              <ul>
                <li className="nav-link sub-item">
                  <a href="/blog">Blog</a>
                </li>
                <li className="nav-link sub-item">
                  <a href="/podcast">Podcast</a>
                </li>
                <li className="nav-link sub-item">
                  <a href="/rich-materials">Materiais Ricos</a>
                </li>
                <li className="nav-link sub-item">
                  <a href="/news">Na mídia</a>
                </li>
              </ul>
            </li>
            <li className="nav-item ">
              <a style={{ textDecoration: "none" }} href="/contact/#">
                Converse conosco
              </a>
            </li>
          </ul>
          <ul id="navbar-lg" className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href="/fabwork/#">
                Sobre
              </a>
            </li>
            <li className="nav-item dropdown">
              <ul>
                <li className="dropdown">
                  <span
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="solution-menu"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="false"
                    aria-expanded="false"
                  >
                    Soluções
                  </span>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="solution-menu"
                  >
                    <div className="dropdown-item" id="fabtech-dropdown">
                      <a href="/fabtech/#">FAB tech</a>
                      <ul className="sub-items">
                        <li>
                          <a href="/fabtech/digital-products">
                            Produtos digitais
                          </a>
                        </li>
                        <li id="poc-sm">
                          <a href="/fabtech/data-science/#">Ciência de dados</a>
                        </li>
                      </ul>
                    </div>
                    <div className="dropdown-item" id="fabcorporate-dropdown">
                      <a href="/fabcorporate/#">FAB corporate</a>
                      <ul className="sub-items">
                        <li>
                          <a href="/fabcorporate/data-driven/#">Data Driven</a>
                        </li>
                        <li>
                          <a href="/fabcorporate/next/#">Next</a>
                        </li>
                        <li>
                          <a href="/fabcorporate/agile/#">Agile</a>
                        </li>
                      </ul>
                    </div>
                    <a
                      className="dropdown-item"
                      id="fabacademy-dropdown"
                      href="/fabacademy/#"
                    >
                      FAB academy
                    </a>
                  </div>
                </li>
              </ul>
            </li>
            <li className="nav-item ">
              <a className="nav-link" href="/#companies">
                Clientes
              </a>
            </li>
            <li className="nav-item">
              <ul>
                <li className="dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#home"
                    id="content-menu"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="false"
                    aria-expanded="false"
                  >
                    Conteúdos
                  </a>
                  <div className="dropdown-menu" aria-labelledby="content-menu">
                    <a className="dropdown-item" href="/blog">
                      Blog
                    </a>
                    <a className="dropdown-item" href="/podcast">
                      Podcast
                    </a>
                    <a className="dropdown-item" href="/rich-materials">
                      Materiais Ricos
                    </a>
                    <a className="dropdown-item" href="/news">
                      Na mídia
                    </a>
                    <a className="dropdown-item" href="/#sectionFour">
                      Juntos Somos Exponenciais
                    </a>
                  </div>
                </li>
              </ul>
            </li>
            <li className="nav-item ">
              <a
                className="nav-link"
                href="/contact/#"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                Converse conosco
              </a>
            </li>
          </ul>
        </div>
        <form className="form-inline">
          <a href="/#newsletter" style={{ textDecoration: "none" }}>
            <button className="btn link" type="button" id="btnComunidade">
              Receba nossa newsletter
              <img
                id="arrow_header"
                src={arrow_header}
                alt="Mais conteúdo abaixo"
              />
            </button>
          </a>
        </form>
      </nav>
      <div id="label" style={{ backgroundColor: "#FFFFFF", height: 2 }}></div>
    </div>
  )
}

export default Header
